import { SerializedStyles } from "@emotion/react";
import { default as GatsbyLink } from "gatsby-link";
import * as React from "react";

export interface Props {
  to?: string;
  branchLink?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  css?: SerializedStyles;
}

export const Link: React.FunctionComponent<Props> = ({
  children,
  to,
  branchLink,
  ...other
}) => {
  const isCms =
    typeof window !== "undefined" && !(window as any).__fitplancms__;
  const internal = /^\/(?!\/)/.test(to);

  if (branchLink) {
    return (
      <a href={branchLink} {...other}>
        {children}
      </a>
    );
  }

  if (!isCms && internal) {
    return (
      <GatsbyLink to={to} {...other}>
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};
