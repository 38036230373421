export const isValidHttpUrl = (inputString) => {
  let url;
  try {
    url = new URL(inputString);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

const isBrowser = typeof window !== `undefined`;
export const navigateTo = (url) => {
  const internal = /^\/(?!\/)/.test(url);
  if (!isBrowser) return null;
  const { origin } = window.location;

  if (internal) {
    return window.location.assign(`${origin}${url || ""}`);
  }
  window.open(url);
};
